.sidebar-item {
  background: #f5f5f5;
  span {
    i.icon {
      margin-right: 20px;
      color: #888888;
    }
  }
}
.sidebar-item.highlight-item {
  span {
    font-weight: 600;
  }

  i.icon {
    color: #ff0002;
  }
}


.sidebar-item-alignment-container {
  display: flex;
  align-items: center;
}