@import '../../../../styles/shared.scss';

.subscription {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i.icon {
    color: $red;
  }

  .new-videos-count {
    color: $grey;
  }
}