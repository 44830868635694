@import '../../styles/shared.scss';

.video-preview {
  display: grid;
  grid: 118px auto / 210px;

  /*
     override grid settings to make VideoPreview horizontal.
     Vertical is default
   */
  &.horizontal {
    grid: auto / 210px auto;

    &.expanded {
      grid: auto / 246px auto;
    }
    grid-column-gap: 4px;
    .video-info {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }
}

.video-info {
  grid-row: 2 / 3; /* is overriden when VideoPreview is horizontal */
  grid-column: 1 / 2; /* is overriden when VideoPreview is horizontal */
  color: $text-color-dark;

  .video-preview-metadata-container {
    padding-top: 5px;
    font-size: 13px;
    color: #6E6E6E;

    .channel-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .show-max-two-lines {
    overflow: hidden;
    line-height: 1.4em;
    max-height: 2.8em;
  }

  .view-and-time {
    margin-bottom: 10px;
  }

  .semi-bold {
    &.expanded {
      font-weight: 400;
      font-size: 1.3rem;
    }
    font-weight: 600;
  }
}

.image-container {
  position: relative;
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  /* Video duration label at bottom right */
  .time-label {
    position: absolute;
    background: $text-color-dark;
    bottom: 0;
    right: 0;
    opacity: 0.8;
    border-radius: 2px;
    font-weight: 500;
    color: white;
    margin: 4px;
    padding: 2px 4px;
    line-height: 12px;
  }
}