.watch-grid {
  display: grid;
  grid-template: auto auto auto 1fr / minmax(0, 1280px) 402px;
  justify-content: center;
  padding-top: 24px;
  column-gap: 24px;
  grid-row-gap: 8px;

  .video {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .metadata {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  .video-info-box {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }
  .related-videos {
    grid-column: 2 / 3;
    grid-row: 1 / span 4;
  }
  .comments {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }
}

// 1280px (max-width of video column) + 402px (width of side bar) + 3 * 24px (empty space on the left, right and between the two columns)
@media(max-width: 1754px) {
  .watch-grid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

