@import '../../styles/shared.scss';

.video-info-box {
  display: grid;
  grid: auto auto / calc(#{$avatar-diameter} + #{$avatar-margin}) auto max-content;
  align-items: center;
  grid-row-gap: 16px;

  .channel-image {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: $avatar-diameter;
    height: $avatar-diameter;
  }

  .video-info {
    grid-row: 1 / 2;
    grid-column: 2 / 3;

    .channel-name {
      font-weight: 600;
      cursor: pointer;
    }
    .video-publication-date {
      font-size: 13px;
      color: #707070;
    }
  }

  .subscribe {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
  }

  .video-description {

    max-width: 615px;
    grid-row: 2 / 3;
    grid-column: 2 / 3;

    button {
      margin: 8px 0;
    }

    p {
      line-height: 1.8rem;
      margin-bottom: 0;
    }

    .collapsed {
      max-height: 3.6rem;
      overflow-y: hidden;
    }

    .expanded {
      max-height: none;
    }
  }
}