.next-up-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .up-next-toggle {
    display: flex;
    align-items: center;

    span {
      margin-right: 10px;
    }
  }
}

