@import '../../styles/shared.scss';

.video-list {
  display: grid;
  grid: auto / auto;
  padding-left: 10rem;
  padding-top: 24px;
  margin-left: $sidebar-left-width;
  grid-row-gap: 10px;
  max-width: 900px;
}

@media(max-width: 1200px) {
  .video-list {
    padding-left: 10px;
  }
}